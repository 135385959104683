import { Typography } from "@mui/material";
import IntendedUse from "./IntendedUse";
import Usage from "./Usage";
import Results from "./Results";
import { useContext } from "react";
import { OperatingManualStyleContext } from "../../../../contexts/OperatingManualStyleContext";

const SystemDescription = () => {
  const { sectionContainerStyle } = useContext(OperatingManualStyleContext);
  return (
    <>
      <div style={sectionContainerStyle}>
        <Typography variant="h4" gutterBottom fontWeight={"bold"}>
          1. System Description
        </Typography>
        <Typography>
          The RetinaLogik RVF100 software solution that works with commercially
          available virtual reality (VR) devices. The solution involves two
          components:
        </Typography>
        <ol>
          <li>
            <span style={{ color: "#0066CC" }}>
              The RetinaLogik VR Application:
            </span>{" "}
            The VR application allows for performance of the visual exam.
          </li>
          <li>
            <span style={{ color: "#0066CC" }}>
              The RetinaLogik Browser Based Portal:
            </span>{" "}
            The web portal is a website for management of patient exams. This is
            where users can create exams for patients and view their results.
            The web portal can be accessed at:{" "}
            <span style={{ color: "blue" }}>portal.retinalogik.ca</span>
          </li>
        </ol>
        <Typography fontWeight={"bold"}>
          The RVF100 software comes preloaded on a customized, commercially
          available virtual reality headset. Necessary accessories are also
          provided to operate the VR unit. This operating manual includes
          instructions on how to use the virtual reality headset as part of the
          RVF100 software solution.
        </Typography>
      </div>
      <IntendedUse />
      <Usage />
      <Results />
    </>
  );
};

export default SystemDescription;
