import { Typography } from "@mui/material";

const SoftwareUpdatesSpecifications = () => {
  return (
    <>
      <Typography variant="h3" component="h1" gutterBottom>
        2. Software Updates & Specifications
      </Typography>
      <Typography>
        A web browser and a stable internet connection of ~ 10 Mb download, and
        ~ 5 Mb upload speed is required to access the RetinaLogik portal.
      </Typography>
      <Typography>
        RetinaLogik will remotely push software updates to both the commercially
        available virtual reality headset and the RetinaLogik Portal. Customers
        will be notified when a virtual reality application update is available
      </Typography>
      <ol>
        <li>
          <span style={{ textDecoration: "underline" }}>
            Virtual reality headset
          </span>
          : To update the virtual reality headset, the device will need to be
          turned on and connected to the internet for 25 minutes.
        </li>
        <li>
          <span style={{ textDecoration: "underline" }}>Portal</span>: The
          portal will automatically be updated. For best results, please clear
          your cache in your browser settings when a new update is pushed.
        </li>
      </ol>
    </>
  );
};

export default SoftwareUpdatesSpecifications;
