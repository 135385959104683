import { Typography } from "@mui/material";
import { useCallback } from "react";
import { Link } from "react-router-dom";

const PortalGuide = () => {
  const onClickUrl = useCallback(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <div id="portal-guide">
      <Typography variant="h5" gutterBottom fontWeight={"bold"}>
        3.4 PortalGuide
      </Typography>
      {/* img */}
      <Typography variant="h5" gutterBottom>
        To learn more about portal functionality, see{" "}
        <Link
          to={`/operating-manual/appendix-c-portal-icon-glossary/`}
          onClick={onClickUrl}
        >
          Appendix C - Portal Icon Glossary
        </Link>
      </Typography>
    </div>
  );
};

export default PortalGuide;
