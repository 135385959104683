import { Typography } from "@mui/material";
import styles from "../index.module.css";

const Features = () => {
  return (
    <div id="features">
      <Typography variant="h5" gutterBottom fontWeight={"bold"}>
        3.1 Features
      </Typography>
      <div className={styles.container}>
        <div className={styles.feature}>
          <span className={styles.label}>Visual Field Grids</span>
          <div class="description">
            24-2 (54 points), 10-2 (68 points), 30-2 (74 points)
          </div>
        </div>

        <div className={styles.feature}>
          <span className={styles.label}>Testing Strategies</span>
          <div class="description">RL Fast, Fast, Standard, Full</div>
        </div>

        <div className={styles.feature}>
          <span className={styles.label}>Live eye tracking</span>
          <div class="description">
            The eye tracking technology tracks the patients gaze throughout the
            test, which can be monitored and managed remotely.
          </div>
        </div>

        <div className={styles.feature}>
          <span className={styles.label}>Stimulus Color</span>
          <div class="description">White</div>
        </div>

        <div className={styles.feature}>
          <span className={styles.label}>Stimulus Size</span>
          <div class="description">
            III, IV, V - Three stimulus sizes (diameters) are available, ranging
            from Size III (smallest) to Size V (largest). The Esterman test uses
            size III only.
          </div>
        </div>

        <div className={styles.feature}>
          <span className={styles.label}>Stimulus Duration</span>
          <div class="description">300 msec</div>
        </div>

        <div className={styles.feature}>
          <span className={styles.label}>Blind spot</span>
          <div class="description">
            The testing method will test the blind spot during the test to
            ensure the patient is focused.
          </div>
        </div>

        <div className={styles.feature}>
          <span className={styles.label}>Auto-Pause</span>
          <div class="description">
            If this feature is turned on, the test will pause if the patient is
            not remaining focused a few times in a row during the test. It will
            remind the patient to remain focused before giving them the option
            to continue.
          </div>
        </div>

        <div className={styles.feature}>
          <span className={styles.label}>Encouragement</span>
          <div class="description">
            If this feature is turned on, the test will play audio prompts to
            encourage the patient at the beginning, middle, and towards the end
            of the test.
          </div>
        </div>

        <div className={styles.feature}>
          <span className={styles.label}>Tutorial</span>
          <div class="description">
            If this feature is selected when creating the test, the application
            will play a guided tutorial in the selected patient language.
          </div>
        </div>

        <div className={styles.feature}>
          <span className={styles.label}>Fixation Focus Feature</span>
          <div class="description">
            If the patient looks away, the fixation point will change color to
            visually remind them to remain focused. In addition, the patient
            will get audio prompt reminders to stay focused if they look away
            from the fixation.
          </div>
        </div>

        <div className={styles.feature}>
          <span className={styles.label}>Livestream</span>
          <div class="description">Livestream patient view in the VR.</div>
        </div>
      </div>
    </div>
  );
};

export default Features;
