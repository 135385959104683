import React, { useContext } from "react";
import { Container, Grid, useMediaQuery, useTheme } from "@mui/material";
import Sidebar from "../../components/UI/Sidebar";
import { Outlet } from "react-router-dom";
import AlgoliaSearch from "../../components/UI/AlgoliaSearch";
import { DeviceAppVersionContext } from "../../contexts/DeviceContext";
import { OperatingManualStyleProvider } from "../../contexts/OperatingManualStyleContext";

const OperationalManual = () => {
  const theme = useTheme();
  const smallerThanSmcreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { newAppVersionWarningPopup } = useContext(DeviceAppVersionContext);
  return (
    <OperatingManualStyleProvider>
      <Container
        maxWidth={false}
        sx={{
          marginTop: newAppVersionWarningPopup ? "20px" : "10px",
          marginBottom: "10px",
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            padding: "0",
            margin: "0",
          }}
        >
          {/* Left side content */}
          <Grid
            item
            xs={12}
            sm={7}
            md={8}
            lg={9}
            pr={!smallerThanSmcreen ? 10 : 4}
          >
            <Outlet />
          </Grid>
          {/* Right side content */}
          {!smallerThanSmcreen && (
            <Grid
              item
              sm={5}
              md={4}
              lg={3}
              px={2}
              sx={{
                bgcolor: "#F7F7F7",
              }}
            >
              <AlgoliaSearch />
              <Sidebar />
            </Grid>
          )}
        </Grid>
      </Container>
    </OperatingManualStyleProvider>
  );
};

export default OperationalManual;
