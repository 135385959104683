import { Typography } from "@mui/material";

const Browser = () => {
  return (
    <>
      <Typography variant="h4" gutterBottom fontWeight={"bold"}>
        7. Browser
      </Typography>
      <Typography>
        RVF100 webportal supports latest versions from Chrome, Edge, Safari and
        Firefox.
      </Typography>
    </>
  );
};

export default Browser;
