import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useCallback, useContext } from "react";
import { Link } from "react-router-dom";
import { OperatingManualStyleContext } from "../../../../../contexts/OperatingManualStyleContext";

const LaunchingExam = () => {
  const theme = useTheme();
  const smallerThanSm = useMediaQuery(theme.breakpoints.down("sm"));
  const { sectionContainerStyle } = useContext(OperatingManualStyleContext);
  const onClickUrl = useCallback(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <div id="launching-exam" style={sectionContainerStyle}>
      <div class="flex items-center text-white bg-baseDark p-2.5 mt-3">
        <Typography variant="h5" fontWeight={"bold"} color={"white"}>
          4.4 Launching an Exam
        </Typography>
      </div>
      {/* img */}
      <Typography>
        To see what the exam looks like from the patient perspective, see{" "}
        <Link
          to={`/operating-manual/appendix-b-patient-view/`}
          onClick={onClickUrl}
        >
          Appendix B - Patient View
        </Link>
        .
      </Typography>
      <Grid container style={sectionContainerStyle}>
        <Grid item display={"flex"} flexDirection={"column"} xs={12} sm={4}>
          {/* img */}
          <Typography>1.</Typography>
          <Typography>
            Show the patient the controller. Instruct them that they will only
            need to press the trigger during the test.
          </Typography>
        </Grid>
        <Grid item display={"flex"} flexDirection={"column"} xs={12} sm={4}>
          {/* img */}
          <Typography>2.</Typography>
          <Typography>
            Loosen the strap by twisting the dial on the device
            counterclockwise. Lift the strap and assist the patient in putting
            on the mask. Ask them to adjust the mask until they can see clearly.
          </Typography>
        </Grid>
        <Grid item display={"flex"} flexDirection={"column"} xs={12} sm={4}>
          {/* img */}
          <Typography>3.</Typography>
          <Typography>
            Rotate the strap down over the head. Instruct the patient to twist
            the dial on the back of the device clockwise to tighten until they
            can see clearly and are comfortable.
          </Typography>
        </Grid>
      </Grid>
      <Typography style={sectionContainerStyle}>
        4. Press the recenter button on the controller and hand it to the
        patient. This will ensure the scene is centered for the patient. The
        patient will be able to see the controller in virtual reality.
      </Typography>
      {!smallerThanSm && (
        <Grid
          style={{ background: "#F7F7F7", color: "rgba(0,0,0, 0.7)" }}
          container
          textAlign={"center"}
        >
          <Grid item sm={4}></Grid>
          <Grid item sm={4}>
            Patient Action
          </Grid>
          <Grid item sm={4}>
            Patient View
          </Grid>
        </Grid>
      )}
      <Grid container style={sectionContainerStyle}>
        <Grid item display={"flex"} flexDirection={"column"} xs={12} sm={4}>
          <Typography>5.</Typography>
          <Typography>
            Instruct the patient to press the trigger button to load the exam
          </Typography>
        </Grid>
        <Grid item display={"flex"} flexDirection={"column"} xs={12} sm={4}>
          {/* img  */}
        </Grid>
        <Grid item display={"flex"} flexDirection={"column"} xs={12} sm={4}>
          {/* img  */}
        </Grid>
      </Grid>
      <Grid container style={sectionContainerStyle}>
        <Grid item display={"flex"} flexDirection={"column"} xs={12} sm={4}>
          <Typography>6.</Typography>
          <Typography>
            Instruct the patient to hold the trigger for 2 seconds to launch the
            exam.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          {/* img  */}
        </Grid>
        <Grid item xs={12} sm={4}>
          {/* img  */}
        </Grid>
      </Grid>
    </div>
  );
};

export default LaunchingExam;
