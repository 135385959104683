import { Typography } from "@mui/material";
import { useCallback, useContext } from "react";
import { Link } from "react-router-dom";
import { OperatingManualStyleContext } from "../../../../../contexts/OperatingManualStyleContext";

const AccountRegistration = () => {
  const { sectionContainerStyle } = useContext(OperatingManualStyleContext);
  const onClickUrl = useCallback(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <div id="account-registration" style={sectionContainerStyle}>
      <div class="flex items-center text-white bg-baseDark p-2.5 mt-3">
        <Typography variant="h5" fontWeight={"bold"} color={"white"}>
          4.1. Account Registration & Initial Set Up
        </Typography>
      </div>
      <Typography>
        During setup, a RetinaLogik representative will request the emails of
        users requiring access to the software solution. Once configured, use
        your web browser to access the portal at{" "}
        <Link to={`http://www.portal.retinalogik.ca/`}>
          www.portal.retinalogik.ca
        </Link>{" "}
        and use the forgot password function to create a secure password for
        your account. Once you receive the VR device, you will need to connect
        it to the internet. You will only need to do this once, as long as the
        Wi-Fi credentials stay the same. To view instructions for connecting the
        VR unit to Wi-Fi, see the{" "}
        <Link
          to={`/operating-manual/virtual-reality-device/`}
          onClick={onClickUrl}
        >
          Set Up Wi-Fi
        </Link>{" "}
        section of this manual.
      </Typography>
    </div>
  );
};

export default AccountRegistration;
