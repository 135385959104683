import { Typography } from "@mui/material";
import AccountRegistration from "./AccountRegistration";
import TurningOnVRDevice from "./TurningOnVRDevice";
import CreatingExam from "./CreatingExam";
import LaunchingExam from "./LaunchingExam";
import ViewingResults from "./ViewingResults";
import { useContext } from "react";
import { OperatingManualStyleContext } from "../../../../contexts/OperatingManualStyleContext";

const InstructionsForUse = () => {
  const { sectionContainerStyle } = useContext(OperatingManualStyleContext);

  return (
    <>
      <div style={sectionContainerStyle}>
        <Typography variant="h4" gutterBottom fontWeight={"bold"}>
          4. Instructions for Use
        </Typography>
      </div>
      <AccountRegistration />
      <TurningOnVRDevice />
      <CreatingExam />
      <LaunchingExam />
      <ViewingResults />
    </>
  );
};

export default InstructionsForUse;
