import { Grid, Typography } from "@mui/material";

const SetUpWiFi = () => {
  return (
    <div id="set-up-wifi">
      <div class="flex items-center text-white bg-baseDark p-2.5 mt-3">
        <Typography variant="h5" fontWeight={"bold"} color={"white"}>
          5.1 Instructions to Set Up Wi-Fi
        </Typography>
      </div>
      <ol>
        <li>
          Turn on the headset and the right controller. Put on the headset.
        </li>
        <li>Press the power/admin menu button quickly.</li>
        <li>
          <Grid container>
            <Grid item xs={12} sm={6}>
              You should be in a garden background and see a menu screen that
              looks like this. Point the controller beam at the “Wi-Fi” section
              and squeeze the trigger to confirm.
            </Grid>
            <Grid item xs={12} sm={6}>
              {/* img */}
            </Grid>
          </Grid>
        </li>
        <li>
          <Grid container>
            <Grid item xs={12} sm={6}>
              You may be asked if you want to quit the paused app. Select “yes”
              and squeeze the trigger to confirm.
            </Grid>
            <Grid item xs={12} sm={6}>
              {/* img */}
            </Grid>
          </Grid>
        </li>
        <li>
          <Grid container>
            <Grid item xs={12} sm={6}>
              Select the Wi-Fi network you would like to connect to from the
              “available networks” menu
            </Grid>
            <Grid item xs={12} sm={6}>
              {/* img */}
            </Grid>
          </Grid>
        </li>
        <li>
          <Grid container>
            <Grid item xs={12} sm={6}>
              Enter the password to your Wi-Fi and press “join”.
            </Grid>
            <Grid item xs={12} sm={6}>
              {/* img */}
            </Grid>
          </Grid>
        </li>
        <li>
          <Grid container>
            <Grid item xs={12} sm={6}>
              The Wi-Fi should now say “connected”.
            </Grid>
            <Grid item xs={12} sm={6}>
              {/* img */}
            </Grid>
          </Grid>
        </li>
        <li>
          <Grid container>
            <Grid item xs={12} sm={6}>
              Point the controller at the “resume app” button in the very bottom
              left of the screen and squeeze the trigger to confirm. This will
              take you back to the application.
            </Grid>
            <Grid item xs={12} sm={6}>
              {/* img */}
            </Grid>
          </Grid>
        </li>
      </ol>
    </div>
  );
};

export default SetUpWiFi;
