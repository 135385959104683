import { Typography } from "@mui/material";
import WarningsPrecautions from "./WarningsPrecautions";
import { useContext } from "react";
import { OperatingManualStyleContext } from "../../../../contexts/OperatingManualStyleContext";

const ImportantInfo = () => {
  const { sectionContainerStyle } = useContext(OperatingManualStyleContext);
  return (
    <>
      <div style={sectionContainerStyle}>
        <Typography
          variant="h4"
          gutterBottom
          fontWeight={"bold"}
          textAlign={"center"}
        >
          Important information – Read Before Use
        </Typography>
        <Typography fontWeight={"bold"}>
          It is important to read this Operating Manual, and the included
          Instructions for Use, carefully before using the RetinaLogik RVF100.
          Please note: In this Operating Manual, the terms “displaying system”
          and “visual field platform” refer to the RetinaLogik RVF100 software.
          The term “VR” or “HMD” refers to the virtual reality head mounted
          device.
        </Typography>
      </div>
      <WarningsPrecautions />
    </>
  );
};

export default ImportantInfo;
