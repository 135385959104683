import { Typography } from "@mui/material";
import { OperatingManualStyleContext } from "../../../../../contexts/OperatingManualStyleContext";
import { useContext } from "react";

const TurningOnVRDevice = () => {
  const { sectionContainerStyle } = useContext(OperatingManualStyleContext);
  return (
    <div id="turning-on-vr-device" style={sectionContainerStyle}>
      <div class="flex items-center text-white bg-baseDark p-2.5 mt-3">
        <Typography variant="h5" fontWeight={"bold"} color={"white"}>
          4.2. Turning on the VR Device
        </Typography>
        {/* img */}
      </div>
      <div
        class="p-2"
        style={{ background: "#F7F7F7", color: "rgba(0,0,0, 0.7)" }}
      >
        Note: You will only need to turn on one controller for the test. Either
        controller will work.
      </div>
    </div>
  );
};

export default TurningOnVRDevice;
