import { Typography } from "@mui/material";

const HeadMountedDeviceGuide = () => {
  return (
    <div id="head-mounted-device-guide">
      <Typography variant="h5" gutterBottom fontWeight={"bold"}>
        3.3 HeadMountedDeviceGuide
      </Typography>
      {/* img */}
      <Typography variant="h5" gutterBottom>
        Note: Refer to
      </Typography>
      {/* img */}
    </div>
  );
};

export default HeadMountedDeviceGuide;
