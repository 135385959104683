import { Typography } from "@mui/material";

const VRDeviceCare = () => {
  return (
    <div id="vr-device-care">
      <div class="flex items-center text-white bg-baseDark p-2.5 mt-3">
        <Typography variant="h5" fontWeight={"bold"} color={"white"}>
          5.2 VR Device Care
        </Typography>
      </div>
      <Typography variant="h5" fontWeight={"bold"}>
        Replacing controller batteries
      </Typography>
      <Typography>
        Locate the small triangle on the controller handle. Press on this area
        while pushing down to slide the panel off, exposing the battery area.
        Remove the old batteries and replace them with 1.5V AA batteries.
      </Typography>
      <Typography variant="h5" fontWeight={"bold"}>
        Lens care
      </Typography>
      <ul>
        <li>
          Avoid exposing the lenses to sunlight at all times as this can cause
          damage
        </li>
        <li>
          Use an optical lens microfiber cleaning cloth dipped in a bit of water
          to wipe clean the lenses. Do not use alcohol, this may damage the
          lenses.
        </li>
        <li>For best results, wipe the lens between use.</li>
        <li>
          For best results, ensure there is no source of light behind the
          patient because it can create reflection on lenses
        </li>
      </ul>
      <Typography variant="h5" fontWeight={"bold"}>
        Face cushion care
      </Typography>
      <Typography>
        Use sterile wipes with an alcohol-based ingredient to gently wipe the
        surfaces that come in contact with skin. If the face cushion begins to
        show signs of wear, please contact RetinaLogik to inquire about options
        to replace the face cushion.
      </Typography>
    </div>
  );
};

export default VRDeviceCare;
