import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { OperatingManualStyleContext } from "../../../../../contexts/OperatingManualStyleContext";
import { useContext } from "react";

const CreatingExam = () => {
  const { sectionContainerStyle } = useContext(OperatingManualStyleContext);
  return (
    <div id="creating-exam" style={sectionContainerStyle}>
      <div class="flex items-center text-white bg-baseDark p-2.5 mt-3">
        <Typography variant="h5" fontWeight={"bold"} color={"white"}>
          4.3. Creating an Exam
        </Typography>
        {/* img */}
      </div>
      <ol>
        <li>
          Use your web browser to open the web portal at{" "}
          <Link to={`http://www.portal.retinalogik.ca/`}>
            www.portal.retinalogik.ca.
          </Link>
        </li>
        <li>Enter your login credentials then click “Continue”.</li>
        <li>
          Select “Patients” in the navigation bar. It should be{" "}
          <span style={{ textDecoration: "underline" }}>underlined</span>.
        </li>
        <li>
          Add a patient using the Add button. Select the patient. Once selected,
          they will be highlighted in dark blue.
          <div class="p-2 bg-gray-100 text-gray-700">
            Note: The language you select when adding a patient will be the
            language of the voice prompts.
          </div>
        </li>
        <li>Add an exam using the Add button</li>
        <li>Select the exam you’d like to create by turning on the slider.</li>
        <div class="p-2 text-white bg-baseDark">
          Note: You can change the schedule date and select a device at this
          stage. These are optional. If you’d like to immediately send the exam
          to a device, select the device from the drop down menu. If you’d like
          to do data entry for exams ahead of time, you can update the schedule
          date for this exam to appear in your dashboard the day of the exam.
        </div>
        <li>
          Configure your preferences for the exam then press the add button.
          <div class="p-2 bg-gray-100 text-gray-700">
            The following step only applies if you{" "}
            <span style={{ textDecoration: "underline" }}>did not</span> select
            a device{" "}
            <pan style={{ textDecoration: "underline" }}>
              when creating an exam
            </pan>
          </div>
        </li>
        <li>Click on the Link button</li>
        <li>
          Select the device you would like to use from the drop-down menu and
          press the link button
          <div class="p-2 bg-gray-100 text-gray-700">
            Note: This green card will appear in the bottom left of your portal
            if linked successfully
          </div>
        </li>
      </ol>
    </div>
  );
};

export default CreatingExam;
