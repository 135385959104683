import { Grid, Typography } from "@mui/material";
import { OperatingManualStyleContext } from "../../../../../contexts/OperatingManualStyleContext";
import { useContext } from "react";

const ViewingResults = () => {
  const { sectionContainerStyle } = useContext(OperatingManualStyleContext);
  return (
    <div id="viewing-results" style={sectionContainerStyle}>
      <div class="flex items-center text-white bg-baseDark p-2.5 mt-3">
        <Typography variant="h5" gutterBottom fontWeight={"bold"}>
          4.5 Viewing the Results
        </Typography>
      </div>
      <div style={sectionContainerStyle}>
        <Grid container style={sectionContainerStyle}>
          <Grid item display={"flex"} flexDirection={"column"} xs={12} sm={6}>
            <Typography>
              1. Press on the expand button next to the exam to monitor the
              patient's progress.
            </Typography>
          </Grid>
          <Grid item display={"flex"} flexDirection={"column"} xs={12} sm={6}>
            {/* img */}
          </Grid>
        </Grid>
        <div style={sectionContainerStyle}> {/* img */}</div>
        <Grid container style={sectionContainerStyle}>
          <Grid item display={"flex"} flexDirection={"column"} xs={12} sm={6}>
            <Typography>2. Click on the view results.</Typography>
          </Grid>
          <Grid item display={"flex"} flexDirection={"column"} xs={12} sm={6}>
            {/* img */}
          </Grid>
        </Grid>
        <Typography>
          3. <span class="text-orange-500 font-bold">Optional:</span> Click on
          the comment button to add a comment to the report.
        </Typography>
        <Typography>
          4. Click on the download button to download a pdf version of the
          results.
        </Typography>
      </div>
      <div class="p-2 bg-gray-100 text-gray-700">
        Note: The VR device will go into sleep mode if it is not being worn for
        a period of time to preserve battery. Once the test is complete, we
        recommend you plug in the VR device. If you have no more patients for
        the day, we recommend you turn the VR device and the controllers off.
      </div>
    </div>
  );
};

export default ViewingResults;
