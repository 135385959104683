import { Typography } from "@mui/material";

const Results = () => {
  return (
    <div id="results">
      <Typography variant="h4" gutterBottoma fontWeight={"bold"}>
        1.3 Results
      </Typography>
      <Typography>
        Visual field results can be interpreted by a medical doctor or
        optometrist or equivalent.
      </Typography>
    </div>
  );
};

export default Results;
