import { Typography } from "@mui/material";
import warningImg from "../../../../../assets/images/icons/warning.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";

const WarningsPrecautions = () => {
  return (
    <div id="warnings-and-precautions">
      <Typography
        variant="h4"
        gutterBottom
        fontWeight={"bold"}
        textAlign={"center"}
      >
        Warnings and Precautions
      </Typography>
      <Typography>
        Failure to observe these warnings and precautions may result in patient
        injury or damage to the equipment. RetinaLogik Inc. is not responsible
        for any damage to the equipment, or patient injury, resulting from
        incorrect use.
      </Typography>
      <div class="flex items-center text-white bg-baseDark p-1.5 mt-3">
        <img src={warningImg} alt="warning" width={"25px"} />
        <Typography variant="h5" fontWeight={"bold"}>
          Warnings
        </Typography>
      </div>
      <div>
        <ol>
          <li>
            Avoid exposing the lenses to sunlight at all times as this can cause
            damage to the lenses. Damage caused by exposure to sunlight is not
            covered in the warranty.
          </li>
          <li>
            Results are meant to be reviewed by an optometrist, or
            ophthalmologist, and used in conjunction with other exams delivered
            at their discretion as part of a vision health assessment.
          </li>
          <li>
            This test is not intended to be used as a sole screening method;
            further testing should be completed before diagnosing a condition.
            It is not intended to be used under any circumstances to treat,
            diagnose, or drive clinical management.
          </li>
          <li>
            To avoid injury, ensure the patient is seated prior to, and for the
            entirety of, wearing the headset.
          </li>
          <li>
            To ensure that records are correctly exported to external systems,
            and to avoid potential misdiagnosis due to mixing-up of patient
            data, carefully check that the patient identifiers are correct
            before starting, saving, and exporting the results.
          </li>
          <li>
            A small number of people may experience epilepsy, fainting,
            dizziness, vomiting, palpitations, and other symptoms caused by
            flashes and images, even if they have no such medical history.
            People wearing VR should consult a doctor before using if they have
            a similar medical history or have ever experienced any of the
            symptoms listed above.
          </li>
          <li>
            Some people may be allergic to plastic, PU, fabric, and other
            materials used in this product. Long-term contact with skin may
            result in symptoms such as redness, swelling, and inflammation. VR
            users should stop using the product and consult a doctor if they
            experience any of the symptoms listed above.
          </li>
          <li>
            If patients have a big difference in binocular vision, or myopia, or
            astigmatism or far-sightedness, it is suggested that they wear
            glasses to correct their eyesight when using VR headset.
          </li>
          <li>
            Patients should stop using the product immediately if they
            experience visual abnormalities (diplopia and sight distortion, eye
            discomfort or pain, etc.), excessive sweating, nausea, vertigo,
            palpitations, disorientation, loss of balance, etc. or other signs
            of distress.
          </li>
          <li>
            Patients should stop use immediately and consult a doctor if the
            following symptoms occur:
            <ul>
              <li>
                Epilepsy seizures, loss of consciousness, convulsions,
                involuntary movements, dizziness, disorientation, nausea,
                somnolence, or fatigue.
              </li>
              <li>
                Eye pain or discomfort, eye fatigue, eye twitching, or visual
                abnormalities (such as illusion, blurred vision, or diplopia).
              </li>
              <li>
                Itchy skin, eczema, swelling, irritation or other discomforts.
              </li>
              <li>
                Excessive sweating, loss of balance, impaired hand-eye
                coordination, or other similar motion sickness symptoms.
              </li>
            </ul>
          </li>
          <li>
            Patients should not operate a motor vehicle, operate machinery, or
            engage in activities that may have potentially serious consequences
            until they have fully recovered from these symptoms.
          </li>
          <li>Do not use liquids near the device.</li>
        </ol>
      </div>
      <div>
        <div style={{ background: "#f2f2f2", padding: 5 }}>
          <Typography variant="h5" fontWeight={"bold"}>
            <FontAwesomeIcon icon={faTriangleExclamation} /> Precautions
          </Typography>
        </div>
        <ol>
          <li>
            The user should read and understand the user manual completely
            before proceeding to use RVF100.
          </li>
          <li>
            To prevent unauthorized access to patient data, it is strongly
            recommended that the computer on which the RVF100 is used is
            password-protected (using a strong password), free of viruses and
            malware, equipped with anti-virus software, has a firewall installed
            and activated; and is updated with the latest security patches.
          </li>
          <li>
            Users should change their portal password regularly and not share it
            with anyone. They should also log out after each session.
          </li>
          <li>
            To avoid malfunction during the procedure, do not use the displaying
            unit if it is damaged in any way. Only use equipment supplied by
            RetinaLogik Inc. Do not try to dismantle the equipment in any way.
          </li>
          <li>
            For best results, wipe the lens between uses. Moist cleaning and
            disinfection wipes can be used to clean the parts of the virtual
            reality headset (except for the lenses) but should not be dripping
            to ensure no damage to internal electronics of the displaying unit.
          </li>
          <li>
            Natural light can damage the lenses in the virtual reality headset.
            Avoid direct sunlight and do not use the system with a window behind
            the user. Use the system in a room with artificial lighting for best
            results (not a dark room).
          </li>
          <li>
            The RVF100 is not intended for use in people under 18 years of age.
          </li>
          <li>
            The clinician is solely responsible for obtaining necessary consents
            for use and disclosure of patient information.
          </li>
        </ol>
      </div>
    </div>
  );
};

export default WarningsPrecautions;
