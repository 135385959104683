import { Typography } from "@mui/material";
import { OperatingManualStyleContext } from "../../../../../contexts/OperatingManualStyleContext";
import { useContext } from "react";

const IntendedUse = () => {
  const { sectionContainerStyle } = useContext(OperatingManualStyleContext);
  return (
    <div id="intended-use" style={sectionContainerStyle}>
      <Typography variant="h4" gutterBottom fontWeight={"bold"}>
        1.1 IntendedUse
      </Typography>
      <Typography>
        The RVF100 consists of a multi-module software application intended for
        use in analyzing retinal sensitivity in adults with glaucoma and other
        visual disorders. The RVF100 can be used on any approved head-mounted
        device (HMD) as long as it meets specific technical benchmarks. The
        RVF100 is intended for use in optometry and ophthalmology clinics by
        doctors and technicians who have received appropriate training on the
        use of this device. Patients who are 18 years of age or older with no
        history of light-induced seizures can take the exams offered in RVF100.
      </Typography>
    </div>
  );
};

export default IntendedUse;
