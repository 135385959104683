import { Grid, Typography } from "@mui/material";
import { useCallback, useContext, useMemo } from "react";
import { OperatingManualStyleContext } from "../../../../contexts/OperatingManualStyleContext";

const FAQ = () => {
  const { sectionContainerStyle } = useContext(OperatingManualStyleContext);
  const makeSectionWrapper = useCallback(
    (title, children) => (
      <div style={sectionContainerStyle}>
        <div class="flex items-center text-white bg-baseDark p-2.5 mt-3">
          <Typography variant="h5" fontWeight={"bold"} color={"white"}>
            {title}
          </Typography>
        </div>
        {children}
      </div>
    ),
    [sectionContainerStyle]
  );

  const Sections = useMemo(
    () => [
      makeSectionWrapper(
        `1. I want to start a new exam, but the VR device is still linked to
      a previous exam. How do I fix this?`,
        <>
          <div class="p-2 bg-gray-100 text-gray-700">
            Note: If an exam has already started you will not be able to link
            the device to another exam.
          </div>
          <Typography>
            In order to link a new exam, you first need to unlink the previous
            test. To do this, unlink the VR device by pressing the “unlink”
            button in the device card. You can find the device card in the
            bottom left corner of your screen. Once unlinked, refresh the device
            using the trigger button on the controller.
          </Typography>
        </>
      ),
      makeSectionWrapper(
        `2. Why can’t I see the controller?`,
        <Typography>
          The controller may not be turned on. Press the power button for 1
          second.
        </Typography>
      ),
      makeSectionWrapper(
        `            3. I had to move, why does the room scene in the headset now look
            off-centre?`,
        <Typography>
          The scene is loaded for your initial position. If you have moved, the
          scene needs to be recentered. To do this, press the recenter button on
          the controller. You can also click the in the expanded exam view.
        </Typography>
      ),
      makeSectionWrapper(
        `            4. The VR device is not working and I want to reset it. What should
            I do?`,
        <Typography>
          Here are the following things you can try, depending on the problem
          <span class="font-bold">(1) Unlink</span> the device if an exam is
          linked to it (see question #1){" "}
          <span class="font-bold">(2) Refresh</span> the VR device using the
          trigger button on the controller (see step 8 on page 15){" "}
          <span class="font-bold">(3) Turn the VR device off and back on</span>.
        </Typography>
      ),
      makeSectionWrapper(
        `5. I forgot my password. How do I reset it?`,
        <Grid container>
          <Grid item xs={12} sm={7}>
            To reset your password, use the “forgot password” button on the
            login screen. From here, you can type in your email address and
            follow the instructions to reset your password.
          </Grid>
          <Grid item xs={12} sm={5}>
            {/* img */}
          </Grid>
        </Grid>
      ),
      makeSectionWrapper(
        `6. How can I tell how much battery is left in the controller?`,
        <Typography>
          You can check the controller battery life by putting on the virtual
          reality headset and turning on the controllers. When you look at the
          controllers you will see a battery life bar. If the controllers are
          running out of battery, their battery bar will turn red. It is
          recommended to keep extra batteries on hand.
        </Typography>
      ),
      makeSectionWrapper(
        `7. How can I edit the settings of an exam that I’ve already started? (ex:
remove the tutorial)`,
        <Typography>
          To edit the exam after it’s started, you’ll need to terminate the
          test. You can do this from the portal by opening the live update and
          pressing terminate. Press the to edit.
        </Typography>
      ),
    ],
    [makeSectionWrapper]
  );

  return (
    <>
      <Typography variant="h4" fontWeight={"bold"}>
        6. FAQ
      </Typography>
      {Sections.map((section, index) => (
        <div key={index}>{section}</div>
      ))}
    </>
  );
};

export default FAQ;
