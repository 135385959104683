import { Typography } from "@mui/material";
import { useContext } from "react";
import { OperatingManualStyleContext } from "../../../../../contexts/OperatingManualStyleContext";

const Usage = () => {
  const { sectionContainerStyle } = useContext(OperatingManualStyleContext);
  return (
    <div id="usage" style={sectionContainerStyle}>
      <Typography variant="h4" gutterBottom fontWeight={"bold"}>
        1.2 Usage
      </Typography>
      <ul>
        <li>Optician</li>
        <li>Nurse</li>
        <li>Technician</li>
        <li>Ophthalmologist</li>
        <li>Optometrists</li>
        <li>Clinical Assistant</li>
      </ul>
    </div>
  );
};

export default Usage;
