import { Typography } from "@mui/material";
import styles from "../index.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faForward } from "@fortawesome/free-solid-svg-icons";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import TargetIcon from "../../../../../assets/images/icons/target.png";

const TestPatterns = () => {
  return (
    <div id="features">
      <Typography variant="h5" gutterBottom fontWeight={"bold"}>
        3.2 Test Patterns
      </Typography>
      <div className={styles.container} id="test-patterns">
        <div className={styles.feature}>
          <span className={`${styles.label}`}>
            <FontAwesomeIcon icon={faForward} />
            RL Fast
          </span>
          <div class="description">
            RL fast is a fast screener that uses suprathreshold stimuli to
            determine the visual field.
          </div>
        </div>

        <div className={styles.feature}>
          <span className={styles.label}>
            <FontAwesomeIcon icon={faForward} />
            Fast
          </span>
          <div class="description">
            Fast uses a statistical method to improve test time over the
            Standard strategy, but it is less sensitive than Standard. It takes
            approximately 3 minutes per eye.
          </div>
        </div>

        <div className={styles.feature}>
          <span className={`${styles.label} ${styles.icon_label}`}>
            <TaskAltIcon />
            Standard
          </span>
          <div class="description">
            Standard is similar to the Full Threshold, but it uses a statistical
            approach to determine which points are most likely to be seen to
            improve test time.
          </div>
        </div>

        <div className={styles.feature}>
          <span className={`${styles.label} ${styles.icon_label}`}>
            <img src={TargetIcon} alt="target" width={"20px"} />
            Full
          </span>
          <div class="description">
            In Full Threshold testing, the stimulus intensity decreases until
            the patient no longer sees the stimulus. It then increases in 2 dB
            steps until the patient responds. It is the most sensitive strategy.
          </div>
        </div>

        <div className={styles.feature}>
          <span className={styles.label}>Esterman</span>
          <div class="description">
            150 degrees bitemporal/ 120 points (Pilot version)
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestPatterns;
