// Library methods
import { useCallback, useEffect, useRef, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate, useLocation } from "react-router-dom";

// MUI Components
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Components
import ClinicsList from "../../containers/Clinics/ClinicsList";
import UsersList from "../../containers/Clinics/UsersList";

// Utilities
import {
  getClinics,
  getClinicUsers,
  updateClinicLicensing,
} from "../../services/Clinic";
import getUserFromToken from "../../utils/tokenHelper";

// Styles
import {
  ModalCustomConfirm,
  PageContainerWrapper,
} from "../../styles/muiStylesHelper";
import useClinic from "../../hooks/useClinic";
import LicensingSwitch from "../../components/UI/LicensingSwitch";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import SnackbarMessage from "../../components/UI/SnackbarMessage";

const Clinics = () => {
  // routing
  const navigate = useNavigate();
  const location = useLocation();

  // auth
  const { getAccessTokenSilently } = useAuth0();

  const { t } = useTranslation();

  // init state
  const [selectedForDelete, setSelectedForDelete] = useState([]);
  // const [selectedClinic, setSelectedClinic] = useState([]);
  const [selectedClinic, setSelectedClinic] = useState(null);
  const [selectedClinicLicensing, setSelectedClinicLicensing] = useState(null);
  const [clinicUsers, setClinicUsers] = useState([]);
  const [toastStatus, setToastStatus] = useState(0); // 1: Fail 2: Success

  // state to track clinic addition and trigger refetch
  const [clinicAdded, setClinicAdded] = useState(false);

  // licensing
  const isUpdated = useRef(false);

  const licensingProperties = [
    "canVideoStream",
    "canUseDicomReports",
    "canUsePngReports",
  ];

  // indicates if the data has been fetchjd, or if it is still being fetched
  const [isClinicsLoading, setIsClinicsLoading] = useState(true);
  const [isUsersLoading, setIsUsersLoading] = useState(true);

  const { setAllClinicInfo, clinicsList } = useClinic();

  const handleClickSave = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();
      const res = await updateClinicLicensing(
        token,
        selectedClinic?.id,
        selectedClinicLicensing
      );

      // Ensure the updated data is set in the all clinics data
      setSelectedClinic((prev) => {
        const result = {
          ...prev,
          licensing: res,
        };

        if (prev?.licensing !== res) isUpdated.current = true;
        return result;
      });

      setToastStatus(2);
    } catch (e) {
      setToastStatus(1);
      console.error(e);
    }
  }, [getAccessTokenSilently, selectedClinic?.id, selectedClinicLicensing]);

  // data fetching
  useEffect(() => {
    // prevent fetching data if clinicsList is not empty and clinic has not been added
    if (clinicsList && clinicsList?.length !== 0 && !clinicAdded) return;

    const fetch = async () => {
      // get authToken
      const token = await getAccessTokenSilently();
      const { isAdmin } = getUserFromToken(token);

      // if user is an admin
      if (isAdmin) {
        // prevents loading indicator from showing if clinicsList is not empty
        clinicsList?.length === 0 && setIsClinicsLoading(true);

        try {
          // get clinics list
          const clinicsList = await getClinics(token);
          setAllClinicInfo(clinicsList);
        } catch (e) {
          //console.log(e);
        } finally {
          setIsClinicsLoading(false);
          setClinicAdded(false);
        }
      } else navigate("/");
    };

    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccessTokenSilently, navigate, clinicAdded]);

  // data fetching

  // method that does the clinic users data fetching, showing the loading indicator
  useEffect(() => {
    const controller = new AbortController();
    const fetchClinicUsers = async () => {
      // get authToken
      const token = await getAccessTokenSilently();
      const { isAdmin } = getUserFromToken(token);

      // if user is an admin
      if (isAdmin) {
        try {
          // get users list
          const clinicUsers = await getClinicUsers(
            token,
            selectedClinic.id,
            controller.signal
          );
          const newClinicUsers = clinicUsers.map((user, index) => ({
            id: index,
            user,
          }));
          setClinicUsers(newClinicUsers);
        } catch (e) {
          //console.log(e);
        }
      } else navigate("/");
    };
    const clinicUsersFetch = async () => {
      try {
        setIsUsersLoading(true);
        await fetchClinicUsers();
      } catch (e) {
        //console.log(e);
      } finally {
        setIsUsersLoading(false);
      }
    };
    clinicUsersFetch();
    return () => {
      controller.abort();
    };
  }, [getAccessTokenSilently, navigate, selectedClinic]);

  // track url state
  useEffect(() => {
    sessionStorage.setItem("previousURL", JSON.stringify(location.pathname));
  }, [location.pathname]);

  useEffect(() => {
    setSelectedClinicLicensing(selectedClinic?.licensing);
    if (isUpdated.current) {
      setAllClinicInfo((prev) => {
        const index = prev.findIndex(
          (clinicInfo) => clinicInfo.id === selectedClinic.id
        );
        const res = [...prev];
        res[index] = selectedClinic;
        return res;
      });
      isUpdated.current = false;
    }
  }, [selectedClinic, selectedClinic?.licensing, setAllClinicInfo]);

  return (
    <Box
      sx={PageContainerWrapper()}
      px={{ xs: 2, sm: 4, md: 6, lg: 6 }}
      py={1.5}
    >
      <Grid
        container
        spacing={2}
        sx={{
          flexDirection: {
            xs: "column",
            sm: "column",
            ls: "column",
            md: "row",
          },
        }}
      >
        <Grid item xs={12} sm={12} md={4.5}>
          <ClinicsList
            isLoading={isClinicsLoading}
            selectedForDelete={selectedForDelete}
            setSelectedForDelete={setSelectedForDelete}
            setSelectedClinic={setSelectedClinic}
            setClinicAdded={setClinicAdded}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={0.5}
          display="flex"
          justifyContent="center"
        >
          <Divider
            sx={{
              maxBorderRightWidth: { xs: 1600, sm: 1200 },
              marginTop: 2,
              borderColor: "#E2772E90",
              height: { xs: 5, sm: 5, md: "97%" },
              borderRightWidth: { md: 3 },
              maxWidth: { xs: 1600, sm: 1200, md: 0 },
            }}
          />
        </Grid>
        <Grid container item xs={12} sm={12} md={7}>
          <Grid item xs={12} height={500}>
            <UsersList
              isLoading={isUsersLoading}
              // isMoreThanOneSelected={selectedClinics.length > 1}
              clinic={selectedClinic}
              clinicId={selectedClinic?.id}
              clinicName={selectedClinic?.name}
              rows={clinicUsers}
              setRows={setClinicUsers}
            />
          </Grid>
          {/* Licensing configuration */}
          {selectedClinic && (
            <Grid container item xs={12}>
              <Grid item xs={12}>
                <LicensingSwitch
                  isOn={
                    selectedClinicLicensing?.[licensingProperties[0]] === true
                  }
                  setSelectedClinicLicensing={setSelectedClinicLicensing}
                  propertyName={licensingProperties[0]}
                  label={t("VR mode")}
                />
                <LicensingSwitch
                  isOn={
                    selectedClinicLicensing?.[licensingProperties[1]] === true
                  }
                  setSelectedClinicLicensing={setSelectedClinicLicensing}
                  propertyName={licensingProperties[1]}
                  label={t("DICOM")}
                />
                <LicensingSwitch
                  isOn={
                    selectedClinicLicensing?.[licensingProperties[2]] === true
                  }
                  setSelectedClinicLicensing={setSelectedClinicLicensing}
                  propertyName={licensingProperties[2]}
                  label={t("PNG")}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  sx={ModalCustomConfirm}
                  onClick={handleClickSave}
                >
                  {t("button_save")}
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      {/* success */}
      <SnackbarMessage
        open={Boolean(toastStatus & (1 << 1))}
        onClose={() => setToastStatus(0)}
        success
        text={t("licensing_update_success")}
      />
      {/* fail */}
      <SnackbarMessage
        open={Boolean(toastStatus & (1 << 0))}
        onClose={() => setToastStatus(0)}
        text={t("licensing_update_fail")}
      />
    </Box>
  );
};

export default Clinics;
